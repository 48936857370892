#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #3bdd6b;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff !important;
  }

  #root, .ant-layout{
      height: 100%
  }

  .ant-layout-footer{
    padding: 5px 50px 15px 50px;
  }

  td > a{
    color: #38b85e !important;
  }