@import 'antd/dist/antd.css';

::-webkit-scrollbar{width:5px;height: 5px;position: absolute;}
::-webkit-scrollbar-thumb{background-color: #1c3f1d;}
::-webkit-scrollbar-track{background-color: #ddd;}

.ant-btn-primary, .ant-switch-checked, .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--primary-color-shade15) !important;
    background-color: var(--primary-color-shade15) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    color: white !important;
    border-color: var(--primary-color) !important;
    background: var(--primary-color) !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:hover,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #00ff4c19 !important;
}
.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover,
.ant-tabs-tab:hover, .ant-tabs-tab:focus, .ant-tabs-tab:active,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active,
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover,
.ant-page-header-back-button:active,
.anticon-check svg {
    color: var(--primary-color-shade15) !important;
}

.ant-input:hover, .ant-input:focus, .ant-picker-focused,
.ant-picker:not(.ant-picker-disabled):hover,
.ant-picker:not(.ant-picker-disabled):focus,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--primary-color);
}
.ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(24, 255, 24, 0.2);
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active,
.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active,
.ant-table-filter-trigger.active, .ant-pagination-item-active a,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:active .ant-pagination-item-link,
.ant-pagination-next:active .ant-pagination-item-link,
.ant-pagination-item a:hover, .ant-pagination-item a:focus,
.ant-pagination-item a:active, .ant-pagination-jump-next:hover svg,
.ant-pagination-jump-next:focus svg, .ant-pagination-jump-next:active svg,
.ant-pagination-jump-prev:hover svg, .ant-pagination-jump-prev:focus svg,
.ant-pagination-jump-prev:active svg {
    color: var(--primary-color-shade15);
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab
.ant-pagination-item, .ant-pagination-item-active, .ant-pagination-item:hover,
.ant-pagination-item:focus, .ant-pagination-item:active,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:before {
    border-color: var(--primary-color-shade15);
}

.ant-spin-text, .ant-picker-now-btn {
    color: #33a154;
}
.ant-spin-dot-item, .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-color);
}

.ant-select-selector {
    background-color: transparent !important;
}
.disabled-field, .disabled-field.ant-select-focused .ant-select-selector {
    background-color: #0000000B;
    border-color: #d9d9d9 !important;
    color: #0000004B;
    box-shadow: none !important;
}
.disabled-field *:hover,
.disabled-field *:focus,
.disabled-field *:active {
    cursor: not-allowed;
    border-color: #D9D9D9 !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: none;
}
.ant-popover-inner-content {
    padding: 0;
}

.card-container .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap,
.card-container .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap > * {
    float: right;
    display: block;
    margin-top: 0.5rem;
}
.ant-tabs-nav > .ant-tabs-extra-content {
    padding-bottom: 0.5rem;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active),
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
    border-color: rgba(59, 221, 107, 0.05);
    background-color: rgba(59, 221, 107, 0.05) !important;
    color: #727272;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 10% 10% 0 0 !important;
}

