/* .ant-form-item-label{
    text-align:center
} */
.uploader-container > span > :last-child {
    min-height: 92px;
    max-height: 92px;
    overflow-y: auto;
}
.uploader-container > span > :last-child:hover {
    box-shadow: inset 0 0.5rem 0.25rem #FAFAFA, inset 0 -0.5rem 0.25rem #FAFAFA;
}