.formContainer{
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 7px;
    padding: 2rem;
    padding-bottom: 1.5rem;
    box-shadow: 2px 2px 10px 1px #444;
}

.loginlogo{
    text-align: center;
    height: 80px;
    line-height: 60px;
    font-size: 22px;
    color: white;
}

.login-form-button{
    width: 100%;
}

.login-form-button {
    color: #fff;
    border-color: #39804f;
    border-radius: 2px;
    background: #39804f;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

#root{
    background-color: #333;
    background-size: cover;
    background-image: url('../../../public/Tiles.png');
    background-position: center;
}
.dark-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top left,#304D45,#D6F7DD);
    opacity: 0.4; 
}