.ant-layout-sider, .ant-menu.ant-menu-dark{
    background-color: #314C44;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: var(--primary-color);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background-color: #264037;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    border-color: var(--primary-color) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: var(--primary-color) !important;
}

.ant-layout-content{
    margin: 10px !important;
}

.logo{
    line-height: 32px;
    margin: 10px 0;
    text-align: center;
}
